<script setup>
import axios from "axios";
import {onMounted, ref} from "vue";

const genre = ref({})

function categoryList() {
  const category = 'Steel Structure'
  const params = new URLSearchParams();
  if (category) {
    params.append('category_1', category)
  }
  let url = '/api/products'
  url = url + '?' + params.toString()
  axios
      .get(url)
      .then(response => {
        genre.value = response.data.results.slice(0, 4);
      })
      .catch(error => {
        console.log(error)
      })
}

onMounted(() => {
  categoryList();
})
</script>

<template>
  <section class="related-product-area py-80 rpy-50">
    <div class="container">
      <div class="section-title text-center mb-55" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
        <h2>Related Product</h2>
      </div>
      <div v-show="genre" class="row">
        <div v-for="list in genre" :key="list.id" class="col-xl-3 col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
          <div class="product-item">
            <div class="image">
              <img :src="list.image_1" alt="Product">
            </div>
            <div class="content">
              <div class="ratting">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
              <h5><a href="">{{ list.product_title }}</a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>