<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {useRoute} from "vue-router";
import {onMounted, ref, watch} from "vue";
import axios from "axios";
import EmailForm from "@/components/EmailForm.vue";
import {useHead} from "@vueuse/head";

const route = useRoute()
const detail = ref(null)

function get_detail(title) {
  let url = '/api/blogs/?blog_title='
  url = url + title
  axios
      .get(url)
      .then(response => {
        detail.value = response.data.results[0]
      })
      .catch(error => {
        console.log(error)
      })
}

onMounted(() => {
  const title = route.params.str
  get_detail(title)
})

watch(detail, (newVal) => {
  if (newVal) {
    useHead({
      title: newVal.blog_title,
      meta: [
        {name: 'description', content: newVal.overview}
      ]
    })
  }
})
</script>

<template>
  <Header/>
  <!-- Page Banner Start -->
  <section class="page-banner-area overlay py-250 rpy-120 rel z-1 bgs-cover text-center"
           style="background-image: url(/static/image/Product.jpg);">
    <div class="container">
      <div class="banner-inner pt-70 rpt-60 text-white">
        <h2 class="page-title" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
          Blog Details</h2>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
              data-aos-offset="50">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item"><a href="/blogs">Blogs</a></li>
            <li v-if="detail" class="breadcrumb-item active">{{ detail.blog_title }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  <!-- Page Banner End -->


  <!-- Project Details Area Start -->
  <section class="project-details-area pt-130 rpt-100 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="project-details-content pb-30" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <div class="section-title mb-30">
              <h2 v-if="detail">{{ detail.project_title }}</h2>
            </div>
            <div class="row mb-20">
              <div class="col-xl-10">
                <p v-if="detail">{{ detail.overview }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="image mb-30" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <img v-if="detail" :src="detail.image_1" alt="Project Details">
          </div>
        </div>
        <div class="col-lg-12">
          <div class="project-details-content mt-15" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <p v-if="detail" v-html="detail.content"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Project Details Area End -->


  <!-- Form Area Start -->
  <section class="related-project-area py-85 rpy-55">
    <div class="container">
      <EmailForm/>
    </div>
  </section>
  <!-- From Area End -->
  <Footer/>
</template>

<style scoped lang="scss">

</style>