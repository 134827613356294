<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmailForm from "@/components/EmailForm.vue";
import {useHead} from "@vueuse/head";

useHead({
  title: 'TPA Housing - 100000m2 Production Plant, 20 Years Experienced Engineer, 30 Countries House Projects',
  meta: [
    {
      name: 'description',
      content: 'content="We can provide prefabricated design expertise, construction knowledge you need to build the house you want in short time and within your budget,Steel Structure House Building,Warehouse Steel Structure,Lightweight Steel Structure and other products and services'
    }
  ]
})
</script>

<template>
  <Header/>
  <!-- Page Banner Start -->
  <section class="page-banner-area overlay py-250 rpy-120 rel z-1 bgs-cover text-center"
           style="background-image: url(/static/image/Product.jpg);">
    <div class="container">
      <div class="banner-inner pt-70 rpt-60 text-white">
        <h1 class="page-title" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">Contact Us</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
              data-aos-offset="50">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Contact Us</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  <!-- Page Banner End -->
  <!-- Contact Page Start -->
  <section class="contact-page py-130 rpy-100">
    <div class="container">
      <div class="row gap-100 align-items-center">
        <div class="col-lg-5">
          <div class="contact-info-part">
            <div class="section-title mb-50" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
              <h2>Feel Free to Contact Us, Get In Touch</h2>
              <p>We're here to assist you in any way we can. Whether you have questions, feedback, or just want to say
                hello</p>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="fal fa-map-marker-alt"></i>
              </div>
              <div class="content">
                <span class="title">Location</span>
                <span
                    class="text">No. 1 Wanguo Market, Mashe Village, Lishui Town, Nanhai, Foshan, Guangdong, China</span>
              </div>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="far fa-envelope-open-text"></i>
              </div>
              <div class="content">
                <span class="title">Email Address</span>
                <span class="text">
                                        <a href="mailto:gdzg@gdzgsteel.com">gdzg@gdzgsteel.com</a>
                                    </span>
              </div>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="fal fa-phone-volume"></i>
              </div>
              <div class="content">
                <span class="title">Make A Call</span>
                <span class="text">
                                        <a href="calto:+86 18024194592">+86 180 2419 4592</a>
                                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="contact-form br-10 bgc-lighter rmt-60" name="contact-form" data-aos="fade-left"
               data-aos-duration="1500" data-aos-offset="50">
            <EmailForm/>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Page End -->
  <Footer/>
</template>

<style scoped lang="scss">

</style>