import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Products from "@/views/Products.vue";
import ProductsDetail from "@/views/ProductsDetail.vue";
import Projects from "@/views/Projects.vue";
import ProjectsDetail from "@/views/ProjectsDetail.vue";
import Blogs from "@/views/Blogs.vue";
import BlogsDetail from "@/views/BlogsDetail.vue";
import NotFound from "@/views/NotFound.vue";
import Contact from "@/views/Contact.vue";
import About from "@/views/About.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/products',
        name: 'Products',
        component: Products
    },
    {
        path: '/products/:str',
        name: 'ProductsDetail',
        component: ProductsDetail,
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects
    },
    {
        path: '/projects/:str',
        name: 'ProjectsDetail',
        component: ProjectsDetail,
    },
    {
        path: '/blogs',
        name: 'Blogs',
        component: Blogs
    },
    {
        path: '/blogs/:str',
        name: 'BlogsDetail',
        component: BlogsDetail
    },
    {
        path: '/:pathMatch(.*)*', // 使用通配符匹配任何路径
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/contact', // 使用通配符匹配任何路径
        name: 'Contact',
        component: Contact
    },
    {
        path: '/about', // 使用通配符匹配任何路径
        name: 'About',
        component: About
    },

    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
