<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";

const route = useRoute()
const dataForm = ref({
  name: '',
  phone: '',
  email: '',
  contents: '',
  client_path: '',
  client_ip: ''
})
const statusMessage = ref('');

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function get_ip() {
  axios
      .get('https://api.ipify.org?format=json')
      .then(response => {
        dataForm.value.client_ip = response.data.ip
      })
      .catch(error => {
        console.log(error)
      })
}
function send_email() {
  dataForm.value.client_path = route.path
  axios
      .post('/api/mail', dataForm.value, {
        headers: {
          'X-CSRFToken': getCookie('csrftoken')
        }
      })
      .then(response => {
        statusMessage.value = 'Email sent successfully!';
      })
      .catch(error => {
        statusMessage.value = 'Failed to send email.';
      })
}

onMounted(() => {
  get_ip()
})
</script>

<template>
  <form @submit.prevent="send_email" id="comment-form" class="comment-form br-15 bgc-lighter mt-75"
        data-aos="fade-up" data-aos-duration="1500"
        data-aos-offset="50" name="comment-form" action="#" method="post">
    <h4>Leave a Reply</h4>
    <div class="row mt-30">
      <div class="col-md-6">
        <div class="form-group">
          <input v-model="dataForm.name" type="text" id="full-name" name="full-name" class="form-control" value=""
                 placeholder="Full Name"
                 required="">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input v-model="dataForm.phone" type="text" id="blog-phone" name="blog-phone" class="form-control"
                 value="" placeholder="Phone"
                 required="">
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <input v-model="dataForm.email" type="email" id="blog-email" name="blog-email" class="form-control"
                 value=""
                 placeholder="Email Address" required="">
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
              <textarea v-model="dataForm.contents" name="message" id="message" class="form-control" rows="4"
                        placeholder="Comments"
                        required=""></textarea>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-0">
          <button type="submit" class="theme-btn">Send Comments <i class="far fa-arrow-right"></i></button>
        </div>
        <p v-if="statusMessage">{{ statusMessage }}</p>
      </div>
    </div>
  </form>
</template>

<style scoped lang="scss">

</style>