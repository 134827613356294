<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmailForm from "@/components/EmailForm.vue";
import {useHead} from "@vueuse/head";

useHead({
  title: 'TPA Housing - 100000m2 Production Plant, 20 Years Experienced Engineer, 30 Countries House Projects',
  meta: [
    {
      name: 'description',
      content: 'content="We can provide prefabricated design expertise, construction knowledge you need to build the house you want in short time and within your budget,Steel Structure House Building,Warehouse Steel Structure,Lightweight Steel Structure and other products and services'
    }
  ]
})
</script>

<template>
  <Header/>
  <!-- Page Banner Start -->
  <section class="page-banner-area overlay py-250 rpy-120 rel z-1 bgs-cover text-center"
           style="background-image: url(/static/image/Product.jpg);">
    <div class="container">
      <div class="banner-inner pt-70 rpt-60 text-white">
        <h1 class="page-title" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">About Company</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
              data-aos-offset="50">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">About Company</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  <!-- Page Banner End -->
  <!-- About Area Start -->
  <section class="about-area py-90 rpy-60">
    <div class="container">
      <div class="row gap-90 align-items-center">
        <div class="col-lg-6">
          <div class="about-images my-40" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
            <img src="/static/picture/The%20steel%20structure%20factory.jpg" alt="About">
            <div class="about-over">
              <img src="/static/picture/steel%20structure.jpg" alt="About">
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content mt-40 rmt-15" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
            <div class="section-title mb-30">
              <h2>Foshan TianPuAn Building Materials Technology Co.,Ltd.</h2>
            </div>
            <p>We are a steel fabrication company that offers quality services including design, manufacturing, and
              project installation management for steel frame building. eg.warehouse, workshop, storage, exhibition
              hall, stadiums, hangars, logistics center, high-rise steel constructions, multi-story residential housing,
              etc.</p>
            <div class="row pt-30">
              <div class="col-sm-6">
                <div class="counter-item counter-text-wrap counted">
                  <span class="count-text percent" data-speed="3000" data-stop="95">95</span>
                  <h5 class="counter-title">Strategy Consulting</h5>
                  <div class="text">Strategy consultants work closely organizations define their</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="counter-item counter-text-wrap counted">
                  <span class="count-text percent" data-speed="3000" data-stop="85">85</span>
                  <h5 class="counter-title">Planning Consulting</h5>
                  <div class="text">provides planning, budgeting</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About Area End -->
  <!-- CTA Area Start -->
  <section class="cta-area bgs-cover py-130 rpy-100" style="background-image: url('/static/image/steel prefab_2.jpg');">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-xl-6 col-lg-8">
          <div class="cta-content text-white rmb-35" data-aos="fade-left" data-aos-duration="1000" data-aos-offset="50">
            <div class="section-title mb-40">
              <span class="subtitle d-block mb-10">Steel Building System</span>
              <h2>Built up famous brand,develop diversified industries.</h2>
            </div>
            <a href="/contact" class="theme-btn">Contact Us <i class="far fa-arrow-right"></i></a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cta-btn text-lg-center text-start ps-lg-0 ps-2" data-aos="zoom-in-right" data-aos-duration="1000">
            <a href="https://www.youtube.com/watch?v=c48nsNCC8Ko" class="mfp-iframe video-play"><i
                class="fas fa-play"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- CTA Area End -->
  <!-- Testimonials Area Start -->
  <section class="testimonials-area pt-130 rpt-100 pb-80 rpb-50">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 col-md-11">
          <div class="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500"
               data-aos-offset="50">
            <h2>Global Clients Say About Our Business Services</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="marquee-slider-right testi-slider-right">
      <div class="testimonial-item">
        <div class="author">
          <div class="image">
            <img src="/static/picture/client1.jpg" alt="Author">
          </div>
          <div class="title"><b>Will /</b> Manager</div>
        </div>
        <div class="author-text fs-5">Will is our first client from the Netherlands, he is very friendly and
          polite.every
          time he comes by will bring us small gifts, just like meeting oldfriends. About his order is a steel structure
          building his private house for living.because the Europe building code is different, we have taken a long
          timeto discuss the material to meet his requirements. Luckily, we were ableto complete the order smoothly.
        </div>
        <div class="testi-footer">
          <div class="ratting">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <span class="text"><span>4.7/5</span> on Trustpilot</span>
        </div>
      </div>
      <div class="testimonial-item">
        <div class="author">
          <div class="image">
            <img src="/static/picture/client4.jpg" alt="Author">
          </div>
          <div class="title"><b>Gutty /</b> Managing Director</div>
        </div>
        <div class="author-text fs-5">This client is running a fruit business in Mauritius, and he was looking fora
          steel
          warehouse to store fruits. We met in Dec-2019, and we have along detailed conversation discussing the steel
          warehouse.We fully listen to his requirements and propose a suitable solution forhim, the client was quite
          satisfied with our service, what a nice meeting.
        </div>
        <div class="testi-footer">
          <div class="ratting">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <span class="text"><span>4.7/5</span> on Trustpilot</span>
        </div>
      </div>
      <div class="testimonial-item">
        <div class="author">
          <div class="image">
            <img src="/static/picture/client3.jpg" alt="Author">
          </div>
          <div class="title"><b>Foued /</b> Manager</div>
        </div>
        <div class="author-text fs-5">We warmly welcome the Foued group from Tunisia to visit our companyin May-2019.
          They
          have a house project coming up and plans to use prefab housemaking method, but this building system is new for
          them, so we made apresentation about the characteristics of the prefabricated house.analyzed related building
          materials to them. They thank us for explainingthe system in such detail.
        </div>
        <div class="testi-footer">
          <div class="ratting">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <span class="text"><span>4.7/5</span> on Trustpilot</span>
        </div>
      </div>
    </div>
  </section>
  <!-- Testimonials Area End -->
  <!-- Management Area Start -->
  <section class="management-area bgp-bottom bgc-navyblue py-60"
           style="background-image: url(/static/image/wave-shape.png);">
    <div class="container">
      <div class="row gap-110 align-items-center">
        <div class="col-lg-6">
          <div class="management-content text-white mt-40" data-aos="fade-left" data-aos-duration="1500"
               data-aos-offset="50">
            <div class="section-title mb-30">
              <h2>Quality control in enterprise management</h2>
            </div>
            <p>Quality is the lifeline of an enterprise</p>
            <div class="row gap-50 pt-25">
              <div class="col-md-6">
                <div class="iconic-box style-nine text-white">
                  <div class="icon">
                    <i class="fal fa-laptop-code"></i>
                  </div>
                  <div class="content">
                    <h5><a href="/contact">Friendly quality control</a></h5>
                    <p>High quality and efficiency, continuous improvement</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="iconic-box style-nine text-white">
                  <div class="icon">
                    <i class="fal fa-cog"></i>
                  </div>
                  <div class="content">
                    <h5><a href="/contact">Powerful quality monitoring</a></h5>
                    <p>High quality and efficiency, continuous improvement.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="management-images my-40" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
            <img src="/static/picture/quality_2.jpg" alt="Management">
            <div class="management-over">
              <img src="/static/picture/quality_1.jpg" alt="Management">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Management Area End -->
  <!-- Recent Certificate Area End -->
  <section class="project-area pt-130 rpt-100 pb-100 rpb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8 col-md-10">
          <div class="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <h2>CERTIFICATE</h2>
            <p>TianPuAn was awarded the title of National High tech Enterprise in2019 and currently has 16 independently
              developed patentedproducts and 14 high-tech products in Guangdong Province.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_1.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_1</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_1.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_2.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_2</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_2.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_3.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_3</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_3.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_4.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_4</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_4.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Recent Certificate Area End -->

  <!-- Contact Page Start -->
  <section class="contact-page py-130 rpy-100">
    <div class="container">
      <div class="row gap-100 align-items-center">
        <div class="col-lg-5">
          <div class="contact-info-part">
            <div class="section-title mb-50" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
              <h2>Feel Free to Contact Us, Get In Touch</h2>
              <p>We're here to assist you in any way we can. Whether you have questions, feedback, or just want to say
                hello</p>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="fal fa-map-marker-alt"></i>
              </div>
              <div class="content">
                <span class="title">Location</span>
                <span
                    class="text">No. 1 Wanguo Market, Mashe Village, Lishui Town, Nanhai, Foshan, Guangdong, China</span>
              </div>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="far fa-envelope-open-text"></i>
              </div>
              <div class="content">
                <span class="title">Email Address</span>
                <span class="text">
                                        <a href="mailto:gdzg@gdzgsteel.com">gdzg@gdzgsteel.com</a>
                                    </span>
              </div>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="fal fa-phone-volume"></i>
              </div>
              <div class="content">
                <span class="title">Make A Call</span>
                <span class="text">
                                        <a href="calto:+86 18024194592">+86 180 2419 4592</a>
                                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="contact-form br-10 bgc-lighter rmt-60" name="contact-form" data-aos="fade-left"
               data-aos-duration="1500" data-aos-offset="50">
            <EmailForm/>
            <!--            <form id="contactForm" class="contactForm" name="contactForm"-->
            <!--                  method="post">-->
            <!--              <img class="shape-one" src="/static/picture/star-yellow-shape.png" alt="Star Shape">-->
            <!--              <img class="shape-two" src="/static/picture/star-black-shape.png" alt="Star Shape">-->
            <!--              <h5>Send Us Message</h5>-->
            <!--              <p>Questions or you would just like to say hello, contact us.</p>-->
            <!--              <div class="row mt-30">-->
            <!--                <div class="col-md-6">-->
            <!--                  <div class="form-group">-->
            <!--                    <label for="name">Full Name</label>-->
            <!--                    <input type="text" id="name" name="name" class="form-control" value="" placeholder="Somaia D. Silva"-->
            <!--                           required="" data-error="Please enter your Name">-->
            <!--                    <div class="help-block with-errors"></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-md-6">-->
            <!--                  <div class="form-group">-->
            <!--                    <label for="email">Email</label>-->
            <!--                    <input type="email" id="email" name="email" class="form-control" value=""-->
            <!--                           placeholder="support@gmail.com" required="" data-error="Please enter your Email">-->
            <!--                    <div class="help-block with-errors"></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-md-12">-->
            <!--                  <div class="form-group">-->
            <!--                    <label for="subject">Subject</label>-->
            <!--                    <input type="text" id="subject" name="subject" class="form-control" value=""-->
            <!--                           placeholder="I like to discussed" required="" data-error="Please enter your Subject">-->
            <!--                    <div class="help-block with-errors"></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-md-12">-->
            <!--                  <div class="form-group">-->
            <!--                    <label for="message">Message</label>-->
            <!--                    <textarea name="message" id="message" class="form-control" rows="4" placeholder="Write Message"-->
            <!--                              required="" data-error="Please enter your Message"></textarea>-->
            <!--                    <div class="help-block with-errors"></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-md-12">-->
            <!--                  <div class="form-group mb-0">-->
            <!--                    <button type="submit" class="theme-btn">Send Us Message <i class="far fa-arrow-right"></i></button>-->
            <!--                    <div id="msgSubmit" class="hidden"></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </form>-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer/>
</template>

<style scoped lang="scss">

</style>