<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {computed, onMounted, ref, watch} from "vue";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import {useHead} from "@vueuse/head";

const dataList = ref(null)
const route = useRoute()
const router = useRouter()
const current = ref(1)
const keywords = ref(null)


onMounted(() => {
  get_product_list();
  current.value = getPageFormUrl()
})

function get_product_list() {
  let url = "/api/products"
  const page = Number(route.query.page)
  const search = keywords.value
  const category_1 = route.query.category_1
  const category_2 = route.query.category_2
  const params = new URLSearchParams()
  if (page) {
    params.append('page', page)
  }
  if (search) {
    params.append('product_title', search)
  }
  if (category_1) {
    params.append('category_1', category_1)
  }
  if (category_2) {
    params.append('category_2', category_2)
  }
  url = url + '?' + params.toString()

  axios
      .get(url)
      .then(response => {
        dataList.value = response.data
      })
      .catch(error => {
        console.log(error)
      })
}

const lastPage = computed(() => {
  return Math.ceil(dataList.value.count / 12)
})

const prevPage = computed(() => {
  if (current.value > 1) {
    return current.value - 1;
  } else {
    return 1
  }
})

const nextPage = computed(() => {
  if (current.value < lastPage.value) {
    return current.value + 1;
  } else {
    return current.value
  }
})

const pages = computed(() => {
  const pages = []
  for (let i = 1; i <= lastPage.value; i++) {
    if (i === current.value || i === lastPage.value || (i >= current.value - 1 && i <= current.value + 1)) {
      pages.push(i);
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  return pages

})

function getPageFormUrl() {
  const page = Number(route.query.page)
  return page ? page : 1
}

function searchMovies() {
  const search = keywords.value.trim()
  router.push({
    query: {product_title: search}
  })
}

function getToPage(page) {
  current.value = page
  const query = {...route.query}
  query.page = page
  router.push({query})
}

watch(() => route.query,
    () => {
      get_product_list()
    },
    {immediate: true})
useHead({
  title: 'TPA Housing - 100000m2 Production Plant, 20 Years Experienced Engineer, 30 Countries House Projects',
  meta: [
    {
      name: 'description',
      content: 'content="We can provide prefabricated design expertise, construction knowledge you need to build the house you want in short time and within your budget,Steel Structure House Building,Warehouse Steel Structure,Lightweight Steel Structure and other products and services'
    }
  ]
})
</script>

<template>
  <Header/>
  <!-- Page Banner Start -->
  <section class="page-banner-area overlay py-250 rpy-120 rel z-1 bgs-cover text-center"
           style="background-image: url(/static/image/Product.jpg);">
    <div class="container">
      <div class="banner-inner pt-70 rpt-60 text-white">
        <h1 class="page-title" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">Products</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
              data-aos-offset="50">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Products</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  <!-- Page Banner End -->


  <!-- Shop Area Start -->
  <section class="shop-area py-130 rpy-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="shop-sidebar rmb-75">
            <div class="widget widget-search" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
              <h5 class="widget-title">Search</h5>
              <form action="#" class="default-search-form">
                <input v-model="keywords" type="text" placeholder="Keywords" required="">
                <button v-on:click.prevent="searchMovies" type="submit" class="searchbutton far fa-search"></button>
              </form>
            </div>
            <div class="widget widget-category" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
                 data-aos-offset="50">
              <h5 class="widget-title">Category</h5>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Steel Structure
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ul>
                        <li><a :href="'?category_1=Steel Structure'">Steel Structure<i
                            class="far fa-arrow-right"></i></a></li>
                        <li><a :href="'?category_2=Steel Building'">Steel Building<i class="far fa-arrow-right"></i></a>
                        </li>
                        <li><a :href="'?category_2=Steel Warehouse'">Steel Warehouse<i
                            class="far fa-arrow-right"></i></a></li>
                        <li><a :href="'?category_2=Steel Workshop'">Steel Workshop<i class="far fa-arrow-right"></i></a>
                        </li>
                        <li><a :href="'?category_2=Steel Shed'">Steel Shed<i class="far fa-arrow-right"></i></a></li>
                        <li><a :href="'?category_2=Steel Fabrication'">Steel Fabrication<i
                            class="far fa-arrow-right"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Prefab House
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ul>
                        <li><a :href="'?category_1=Prefab House'">Prefab House<i class="far fa-arrow-right"></i></a>
                        </li>
                        <li><a :href="'?category_2=K Type Prefab House'">K Type Prefab House<i
                            class="far fa-arrow-right"></i></a></li>
                        <li><a :href="'?category_2=T Type Prefab House'">T Type Prefab House<i
                            class="far fa-arrow-right"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Steel House
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ul>
                        <li><a :href="'?category_1=Steel House'">Steel House<i class="far fa-arrow-right"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-9">
          <div class="shop-page-wrap">
            <div class="row">
              <div v-if="dataList && dataList.results" v-for="list in dataList.results" :key="list.rank"
                   class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
                   data-aos-offset="50">
                <div class="product-item">
                  <div class="image">
                    <img :src="list.image_1" alt="Product">
                  </div>
                  <div class="content">
                    <div class="ratting">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                    <h5><a :href="'/products/' + list.product_title">{{ list.product_title }}</a></h5>
                    <a :href="'/products/' + list.product_title" class="btn btn-primary"> Read </a>
                  </div>
                </div>
              </div>

            </div>
            <ul class="pagination mt-10 flex-wrap" data-aos="fade-up" data-aos-duration="1500"
                data-aos-offset="50">
              <li class="page-item">
                <span class="page-link" @click="getToPage(prevPage)"><i class="fas fa-angle-left"></i></span>
              </li>
              <li v-if="dataList && dataList.results" v-for="page in pages" :key="page" class="page-item">
                <span v-if="page === current.value" class="page-link" @click="getToPage(page)">
                    {{ page }}
                    <span class="sr-only">(current)</span>
                </span>
                <span v-else-if="page === '...'" class="page-link">
                    {{ page }}
                </span>
                <span v-else class="page-link" @click="getToPage(page)">
                    {{ page }}
                <span class="sr-only">(current)</span>
                </span>
              </li>
              <li class="page-item">
                <a class="page-link" @click="getToPage(nextPage)"><i class="fas fa-angle-right"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Shop Area End -->
  <Footer/>
</template>

<style scoped lang="scss">

</style>