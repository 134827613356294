<script setup>

</script>

<template>
  <!-- Preloader -->
  <div class="preloader">
    <div class="custom-loader"></div>
  </div>

  <!-- main header -->
  <header class="main-header menu-absolute header-white no-border">

    <!--Header-Upper-->
    <div class="header-upper">
      <div class="container container-1660 clearfix">

        <div class="header-inner py-15 rel d-flex align-items-center">
          <div class="logo-outer">
            <div class="logo" style="width: 30%; height: 30%;"><a href=""><img src="/static/picture/logo1.jpg"
                                                                               alt="Logo" title="Logo"></a></div>
          </div>

          <div class="nav-outer ms-lg-auto clearfix">
            <!-- Main Menu -->
            <nav class="main-menu navbar-expand-lg">
              <div class="navbar-header py-10">
                <div class="mobile-logo" style="width: 30%; height: 30%;">
                  <a href="">
                    <img src="/static/picture/logo.png" alt="Logo" title="Logo">
                  </a>
                </div>

                <!-- Toggle Button -->
                <button type="button" class="navbar-toggle" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
              </div>

              <div class="navbar-collapse collapse clearfix">
                <ul class="navigation clearfix">
                  <li><a href="/">Home</a>
                  </li>
                  <li><a href="/about">About Us</a>
                  </li>
                  <li class="dropdown"><a href="/products" onclick="window.location.href='/products'; return false;">Products</a>
                    <ul>
                      <li class="dropdown">
                        <a :href="'/products?category_1=Steel Structure'"
                           onclick="window.location.href='/products?category_1=Steel Structure'; return false;">Steel
                          Structure</a>
                        <ul>
                          <li><a :href="'/products?category_2=Steel Building'">Steel Building</a></li>
                          <li><a :href="'/products?category_2=Steel Warehouse'">Steel Warehouse</a></li>
                          <li><a :href="'/products?category_2=Steel Workshop'">Steel Workshop</a></li>
                          <li><a :href="'/products?category_2=Steel Shed'">Steel Shed</a></li>
                          <li><a :href="'/products?category_2=Steel Fabrication'">Steel Fabrication</a></li>
                        </ul>
                      </li>
                      <li class="dropdown"><a :href="'/products?category_1=Prefab House'"
                                              onclick="window.location.href='/products?category_1=Prefab House'; return false;">Prefab
                        House</a>
                        <ul>
                          <li><a :href="'/products?category_2=K Type Prefab House'">K Type Prefab House</a></li>
                          <li><a :href="'/products?category_2=T Type Prefab House'">T Type Prefab House</a></li>
                        </ul>
                      </li>
                      <li><a :href="'/products?category_1=Steel House'">Steel House</a></li>
                    </ul>
                  </li>
                  <li><a href="/projects">Projects</a>
                  </li>
                  <li class="dropdown"><a href="/blogs">Blog</a>
                    <ul>
                      <li><a :href="'/blogs/?category=Industry'">Industry Blog</a></li>
                    </ul>
                  </li>
                  <li><a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>

            </nav>
            <!-- Main Menu End-->
          </div>

          <!-- Nav Search -->
          <div class="nav-search ms-xl-2 ms-4 py-10">
            <button class="far fa-search"></button>
            <form action="#" class="hide">
              <input type="text" placeholder="Search" class="searchbox" required="">
              <button type="submit" class="searchbutton far fa-search"></button>
            </form>
          </div>

          <!-- Menu Button -->
          <div class="menu-btns ms-lg-auto d-none d-xl-flex">
            <a href="/contact" class="theme-btn">Get Started <i class="far fa-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!--End Header Upper-->
  </header>
</template>

<style scoped lang="scss">

</style>