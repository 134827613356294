<template>
  <Header/>
  <!-- Hero area start -->
  <section class="hero-area">

    <div id="carouselExampleDark" class="carousel carousel-dark slide">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
          <div class="bgs-cover py-250 rpy-150 overlay"
               style="background-image: url('/static/image/steel structure.jpg');">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-10 col-md-11">
                  <div class="hero-content text-center text-white">
												<span class="subtitle-one mb-20" data-aos="fade-up"
                              data-aos-duration="1500" data-aos-offset="50"><i
                            class="fas fa-rocket"></i> Building Materials Technology Co.,Ltd.</span>
                    <h1 data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
                        data-aos-offset="50" class="fs-2">Founded in 2008, Foshan TianPuAn Building Materials Technology
                      Co., Ltd.(for short “TPA Housing”).</h1>
                    <div class="hero-btns" data-aos="fade-up" data-aos-delay="400"
                         data-aos-duration="1500" data-aos-offset="50">
                      <a href="/about" class="theme-btn">Learn More <i
                          class="far fa-arrow-right"></i></a>
                      <a href="/products" class="theme-btn style-two">Our Products <i
                          class="far fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <div class="bgs-cover py-250 rpy-150 overlay"
               style="background-image: url('/static/image/steel building.jpg');">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-10 col-md-11">
                  <div class="hero-content text-center text-white">
												<span class="subtitle-one mb-20" data-aos="fade-up"
                              data-aos-duration="1500" data-aos-offset="50"><i
                            class="fas fa-rocket"></i> Building Materials Technology Co.,Ltd.</span>
                    <h1 data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
                        data-aos-offset="50" class="fs-2">Founded in 2008, Foshan TianPuAn Building Materials Technology
                      Co., Ltd.(for short “TPA Housing”).</h1>
                    <div class="hero-btns" data-aos="fade-up" data-aos-delay="400"
                         data-aos-duration="1500" data-aos-offset="50">
                      <a href="/about" class="theme-btn">Learn More <i
                          class="far fa-arrow-right"></i></a>
                      <a href="/products" class="theme-btn style-two">Our Products <i
                          class="far fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="bgs-cover py-250 rpy-150 overlay"
               style="background-image: url('/static/image/steel construction.jpg');">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-10 col-md-11">
                  <div class="hero-content text-center text-white">
													<span class="subtitle-one mb-20" data-aos="fade-up"
                                data-aos-duration="1500" data-aos-offset="50"><i
                              class="fas fa-rocket"></i> Building Materials Technology Co.,Ltd.</span>
                    <h1 data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
                        data-aos-offset="50" class="fs-2">Founded in 2008, Foshan TianPuAn Building Materials Technology
                      Co., Ltd.(for short “TPA Housing”).</h1>
                    <div class="hero-btns" data-aos="fade-up" data-aos-delay="400"
                         data-aos-duration="1500" data-aos-offset="50">
                      <a href="/about" class="theme-btn">Learn More <i
                          class="far fa-arrow-right"></i></a>
                      <a href="/products" class="theme-btn style-two">Our Products <i
                          class="far fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
              data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
              data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

  </section>

  <!-- Hero area End -->


  <!-- Services Area Start -->
  <section class="services-area bgp-bottom bgc-navyblue pb-55 rel z-2"
           style="background-image: url(/static/image/wave-shape.png);">
    <div class="container">
      <div class="services-wrap">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <div class="iconic-box">
              <div class="icon">
                <i class="flaticon-customer-service-1"></i>
              </div>
              <div class="content">
                <h4><a href="/about">Best Quality Services</a></h4>
                <p>Make every service a wonderful experience.</p>
                <hr>
                <a href="/about" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/iconic-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"
               data-aos-offset="50">
            <div class="iconic-box">
              <div class="icon">
                <i class="flaticon-idea"></i>
              </div>
              <div class="content">
                <h4><a href="/about">Innovation Ideas</a></h4>
                <p>New thinking in steel construction and new heights in architecture.</p>
                <hr>
                <a href="/about" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/iconic-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
               data-aos-offset="50">
            <div class="iconic-box">
              <div class="icon">
                <i class="flaticon-earning"></i>
              </div>
              <div class="content">
                <h4><a href="/about">Business Growth</a></h4>
                <p>Let's grow together and create brilliance together.</p>
                <hr>
                <a href="/about" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/iconic-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-9">
          <blockquote class="blockquote-one text-white" data-aos="fade-up" data-aos-duration="1000"
                      data-aos-offset="50">
            <div class="text">Business consulting involves the provision expert advice and guidance to organizations
              seeking to improve their performance, solve specific problems, or achieve specific objectives. The primary
              purpose of business consultants is to leverage their expertise and experience to help clients make
              informed decisions, develop strategies, and implement
            </div>
            <div class="author">
              <img src="/static/picture/client2.jpg" alt="Author">
              <div class="name"><h5>Arun/</h5> <span>Projects Manager</span></div>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  </section>
  <!-- Services Area End -->


  <!-- About Area Start -->
  <section class="about-area py-90 rpy-60">
    <div class="container">
      <div class="row gap-90 align-items-center">
        <div class="col-lg-6">
          <div class="about-images my-40" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
            <img src="/static/picture/The%20steel%20structure%20factory.jpg" alt="About">
            <div class="about-over">
              <img src="/static/picture/steel%20structure.jpg" alt="About">
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content mt-40 rmt-15" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
            <div class="section-title mb-30">
              <h2>Foshan TianPuAn Building Materials Technology Co.,Ltd.</h2>
            </div>
            <p>We are a steel fabrication company that offers quality services including design, manufacturing, and
              project installation management for steel frame building. eg.warehouse, workshop, storage, exhibition
              hall, stadiums, hangars, logistics center, high-rise steel constructions, multi-story residential housing,
              etc.</p>
            <div class="row pt-30">
              <div class="col-sm-6">
                <div class="counter-item counter-text-wrap counted">
                  <span class="count-text percent" data-speed="3000" data-stop="95">95</span>
                  <h5 class="counter-title">Strategy Consulting</h5>
                  <div class="text">Strategy consultants work closely organizations define their</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="counter-item counter-text-wrap counted">
                  <span class="count-text percent" data-speed="3000" data-stop="85">85</span>
                  <h5 class="counter-title">Planning Consulting</h5>
                  <div class="text">provides planning, budgeting</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About Area End -->


  <!-- Solutions Area End -->
  <section class="solutions-area pb-100 rpb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 col-md-11">
          <div class="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <h2>Discover Steel Structure Solutions Tailored to Your Needs</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="fancy-box" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/products.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <div class="icon-title">
                <i class="flaticon-meeting"></i>
                <h5><a href="/products">Products Consulting</a></h5>
              </div>
              <div class="inner-content">
                <p>Assist customers in product understanding</p>
                <a href="/products" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="fancy-box" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Quotation.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <div class="icon-title">
                <i class="flaticon-financial-advisor"></i>
                <h5><a href="service-details.html">Quotation consultation</a></h5>
              </div>
              <div class="inner-content" style="display: none;">
                <p>Assist customers in product price planning</p>
                <a href="service-details.html" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="fancy-box" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/design.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <div class="icon-title">
                <i class="flaticon-meeting"></i>
                <h5><a href="service-details.html">Design consulting</a></h5>
              </div>
              <div class="inner-content" style="display: none;">
                <p>Assist clients in product design planning</p>
                <a href="service-details.html" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="fancy-box" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/construction.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <div class="icon-title">
                <i class="flaticon-brand-identity"></i>
                <h5><a href="service-details.html">Production consultation</a></h5>
              </div>
              <div class="inner-content" style="display: none;">
                <p>Assist customers in understanding product production</p>
                <a href="service-details.html" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="fancy-box" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/project.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <div class="icon-title">
                <i class="flaticon-technology"></i>
                <h5><a href="service-details.html">Engineering consulting</a></h5>
              </div>
              <div class="inner-content" style="display: none;">
                <p>Assist clients in answering engineering questions</p>
                <a href="service-details.html" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="fancy-box" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/quality.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <div class="icon-title">
                <i class="flaticon-talent-search"></i>
                <h5><a href="service-details.html">Quality consulting</a></h5>
              </div>
              <div class="inner-content" style="display: none;">
                <p>Assist customers in recognizing quality</p>
                <a href="service-details.html" class="read-more">Read More <i class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Solutions Area End -->


  <!-- CTA Area Start -->
  <section class="cta-area bgs-cover py-130 rpy-100" style="background-image: url('/static/image/steel prefab_2.jpg');">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-xl-6 col-lg-8">
          <div class="cta-content text-white rmb-35" data-aos="fade-left" data-aos-duration="1000" data-aos-offset="50">
            <div class="section-title mb-40">
              <span class="subtitle d-block mb-10">Steel Building System</span>
              <h2>Built up famous brand,develop diversified industries.</h2>
            </div>
            <a href="contact.html" class="theme-btn">Contact Us <i class="far fa-arrow-right"></i></a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cta-btn text-lg-center text-start ps-lg-0 ps-2" data-aos="zoom-in-right" data-aos-duration="1000">
            <a href="https://www.youtube.com/watch?v=c48nsNCC8Ko" class="mfp-iframe video-play"><i
                class="fas fa-play"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- CTA Area End -->


  <!-- Recent Projects Area End -->
  <section class="project-area pt-130 rpt-100 pb-100 rpb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8 col-md-10">
          <div class="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <h2>Explore Our Case Studies & Products</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/product_1.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <a href="/products?category_2=Steel Warehouse" class="category">steel warehouse construction</a>
              <div class="inner-content">
                <a href="/products?category_2=Steel Warehouse" class="read-more">Read More <i
                    class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/product_2.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <a href="/products?category_2=Steel Workshop" class="category">light gauge steel construction</a>
              <div class="inner-content">
                <a href="/products?category_2=Steel Workshop" class="read-more">Read More <i
                    class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/product_3.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <a href="/products?category_2=Steel Fabrication" class="category">Light Steel Frame Workshop</a>
              <div class="inner-content">
                <a href="/products?category_2=Steel Fabrication" class="read-more">Read More <i
                    class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/product_4.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <a href="/products?category_2=Steel Warehouse" class="category">Steel Warehouse Buildings</a>
              <div class="inner-content">
                <a href="/products?category_2=Steel Warehouse" class="read-more">Read More <i
                    class="far fa-arrow-right"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Recent Projects Area End -->


  <!-- Counter TimeLine Area Start -->
  <div class="counter-timeline-area">
    <div class="container">
      <div class="counter-timeline-wrap">
        <div class="row no-gap justify-content-center">
          <div class="col-lg-4 col-sm-6">
            <div class="counter-timeline-item counter-text-wrap" data-aos="fade-up" data-aos-delay="200"
                 data-aos-duration="1000" data-aos-offset="50">
              <div class="icon"><i class="flaticon-review"></i></div>
              <span class="dots">
                                    <img src="/static/picture/counter-dots.png" alt="Shape">
                                </span>
              <div class="content">
                <span class="count-text k-plus" data-speed="3000" data-stop="60">0</span>
                <span class="counter-title">Export</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="counter-timeline-item counter-text-wrap" data-aos="fade-down" data-aos-duration="1000"
                 data-aos-offset="50">
              <div class="content">
                <span class="count-text k-plus" data-speed="3000" data-stop="58">0</span>
                <span class="counter-title">Team</span>
              </div>
              <span class="dots">
                                    <img src="/static/picture/counter-dots.png" alt="Shape">
                                </span>
              <div class="icon"><i class="flaticon-layers-1"></i></div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="counter-timeline-item counter-text-wrap" data-aos="fade-up" data-aos-delay="400"
                 data-aos-duration="1000" data-aos-offset="50">
              <div class="icon"><i class="flaticon-online-registration"></i></div>
              <span class="dots">
                                    <img src="/static/picture/counter-dots.png" alt="Shape">
                                </span>
              <div class="content">
                <span class="count-text plus" data-speed="3000" data-stop="4">0</span>
                <span class="counter-title">Certificates</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Counter TimeLine Area End -->


  <!-- Management Area Start -->
  <section class="management-area bgp-bottom bgc-navyblue py-60"
           style="background-image: url(/static/image/wave-shape.png);">
    <div class="container">
      <div class="row gap-110 align-items-center">
        <div class="col-lg-6">
          <div class="management-content text-white mt-40" data-aos="fade-left" data-aos-duration="1500"
               data-aos-offset="50">
            <div class="section-title mb-30">
              <h2>Quality control in enterprise management</h2>
            </div>
            <p>Quality is the lifeline of an enterprise</p>
            <div class="row gap-50 pt-25">
              <div class="col-md-6">
                <div class="iconic-box style-nine text-white">
                  <div class="icon">
                    <i class="fal fa-laptop-code"></i>
                  </div>
                  <div class="content">
                    <h5><a href="service-details.html">Friendly quality control</a></h5>
                    <p>High quality and efficiency, continuous improvement</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="iconic-box style-nine text-white">
                  <div class="icon">
                    <i class="fal fa-cog"></i>
                  </div>
                  <div class="content">
                    <h5><a href="service-details.html">Powerful quality monitoring</a></h5>
                    <p>High quality and efficiency, continuous improvement.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="management-images my-40" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
            <img src="/static/picture/quality_2.jpg" alt="Management">
            <div class="management-over">
              <img src="/static/picture/quality_1.jpg" alt="Management">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Management Area End -->


  <!-- Testimonials Area Start -->
  <section class="testimonials-area pt-130 rpt-100 pb-80 rpb-50">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 col-md-11">
          <div class="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500"
               data-aos-offset="50">
            <h2>Global Clients Say About Our Business Services</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="marquee-slider-right testi-slider-right">
      <div class="testimonial-item">
        <div class="author">
          <div class="image">
            <img src="/static/picture/client1.jpg" alt="Author">
          </div>
          <div class="title"><b>Will /</b> Manager</div>
        </div>
        <div class="author-text fs-5">Will is our first client from the Netherlands, he is very friendly and
          polite.every
          time he comes by will bring us small gifts, just like meeting oldfriends. About his order is a steel structure
          building his private house for living.because the Europe building code is different, we have taken a long
          timeto discuss the material to meet his requirements. Luckily, we were ableto complete the order smoothly.
        </div>
        <div class="testi-footer">
          <div class="ratting">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <span class="text"><span>4.7/5</span> on Trustpilot</span>
        </div>
      </div>
      <div class="testimonial-item">
        <div class="author">
          <div class="image">
            <img src="/static/picture/client4.jpg" alt="Author">
          </div>
          <div class="title"><b>Gutty /</b> Managing Director</div>
        </div>
        <div class="author-text fs-5">This client is running a fruit business in Mauritius, and he was looking fora
          steel
          warehouse to store fruits. We met in Dec-2019, and we have along detailed conversation discussing the steel
          warehouse.We fully listen to his requirements and propose a suitable solution forhim, the client was quite
          satisfied with our service, what a nice meeting.
        </div>
        <div class="testi-footer">
          <div class="ratting">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <span class="text"><span>4.7/5</span> on Trustpilot</span>
        </div>
      </div>
      <div class="testimonial-item">
        <div class="author">
          <div class="image">
            <img src="/static/picture/client3.jpg" alt="Author">
          </div>
          <div class="title"><b>Foued /</b> Manager</div>
        </div>
        <div class="author-text fs-5">We warmly welcome the Foued group from Tunisia to visit our companyin May-2019.
          They
          have a house project coming up and plans to use prefab housemaking method, but this building system is new for
          them, so we made apresentation about the characteristics of the prefabricated house.analyzed related building
          materials to them. They thank us for explainingthe system in such detail.
        </div>
        <div class="testi-footer">
          <div class="ratting">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <span class="text"><span>4.7/5</span> on Trustpilot</span>
        </div>
      </div>
    </div>
  </section>
  <!-- Testimonials Area End -->


  <!-- Blog Area Start -->
  <section class="blog-area pb-100 rpb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 col-md-11">
          <div class="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <h2>Get Every Single Updates and Learn Our News & Blog</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="blog-item style-two" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/steel%20prefab_1.jpg" alt="Blog">
              <div class="date"><b>25</b><span>Sep</span></div>
            </div>
            <div class="content">
              <a href="/blogs" class="category">steel structure</a>
              <h5><a href="/blogs">2335 m2 steel structure office and warehouse(Israel)</a></h5>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="blog-item style-two" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/steel%20prefab_2.jpg" alt="Blog">
              <div class="date"><b>28</b><span>Sep</span></div>
            </div>
            <div class="content">
              <a href="/blogs" class="category">prefab light steel</a>
              <h5><a href="/blogs">172sqm prefab light steel gauge house(Romania)</a></h5>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-10">
          <div class="blog-item style-two" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/steel%20prefab_3.jpg" alt="Blog">
              <div class="date"><b>30</b><span>Sep</span></div>
            </div>
            <div class="content">
              <a href="/blogs" class="category">Steel Structure Farm</a>
              <h5><a href="/blogs">Hot Dip Galvanized Steel Structure Chicken Farm Building</a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog Area End -->


  <!-- FAQs Area Start -->
  <section class="faqs-area pt-130 rpt-100">
    <div class="container">
      <div class="row gap-70">
        <div class="col-lg-5">
          <div class="faq-left-content rmb-55" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
            <div class="section-title mb-30">
              <h2>Why choose Tpahousing</h2>
            </div>
            <p>We tailor each building to your needs with the most efficient, innovative, and cost-effective design —
              even when it means being a little unorthodox in our approach.</p>
            <a href="#" class="theme-btn mt-30">Contact Us <i class="far fa-arrow-right"></i></a>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="accordion faq-page" id="accordionOne" data-aos="fade-right" data-aos-duration="1500"
               data-aos-offset="50">
            <div class="accordion-item">
              <h5 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne1">
                  1.What is the maximum depth that your steel building can span?
                </button>
              </h5>
              <div id="collapseOne1" class="accordion-collapse collapse" data-bs-parent="#accordionOne">
                <div class="accordion-body">
                  <p>Our buildings are engineered to span from 9 meters up to 40 meters clear span and further if
                    needed.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="accordion-header">
                <button class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne2">
                  2.How to protect steel structure frame from rusting?
                </button>
              </h5>
              <div id="collapseOne2" class="accordion-collapse collapse show" data-bs-parent="#accordionOne">
                <div class="accordion-body">
                  <p>There are two methods:2 layers(30microns+30microns)auti-rust coating and hot-dip galvanized.If the
                    house is near the coast wesuggest using galvanized solution,cause it can last longer.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne3">
                  3.Is it easy to install a steel building?
                </button>
              </h5>
              <div id="collapseOne3" class="accordion-collapse collapse" data-bs-parent="#accordionOne">
                <div class="accordion-body">
                  <p>It is not so difficult to install this kind of steel house building, we will guide you with an
                    installation instruction drawing, the construction is a simple bolt-together process, and you just
                    need a crane of about 20ton to help with the installation work.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne4">
                  4.Why does wind speed affect prices that much?
                </button>
              </h5>
              <div id="collapseOne4" class="accordion-collapse collapse" data-bs-parent="#accordionOne">
                <div class="accordion-body">
                  <p>For most of the steel structure buildings, we design wind speed is about 110km/h. But for some
                    strong wind countries,110km/h is not enough, like the Philippines and Mauritius 150~280km/h
                    windspeed need to enlarge the beam and column, that will use more steel on it then increase the
                    price a lot.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne5">
                  5.What accessories do you provide for customizing steel buildings?
                </button>
              </h5>
              <div id="collapseOne5" class="accordion-collapse collapse" data-bs-parent="#accordionOne">
                <div class="accordion-body">
                  <p>Along with the wall and roof insulation systems, we also offer skylights, commercial-grade
                    ventilation devices such as roof turbine vents and wall louver vents, rolling doors, and double
                    sliding doors.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h5 class="accordion-header">
                <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne6">
                  6.Is it beter to add a brick wall to my metal building?
                </button>
              </h5>
              <div id="collapseOne6" class="accordion-collapse collapse" data-bs-parent="#accordionOne">
                <div class="accordion-body">
                  <p>Yes,it is.The brick wall can be moisture resistance and also fire resistance. 1~3m high brick wall
                    combine the metal sheet or sandwich panel will be durable and look nice.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- FAQs Area End -->

  <!-- Recent Certificate Area End -->
  <section class="project-area pt-130 rpt-100 pb-100 rpb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8 col-md-10">
          <div class="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <h2>CERTIFICATE</h2>
            <p>TianPuAn was awarded the title of National High tech Enterprise in2019 and currently has 16 independently
              developed patentedproducts and 14 high-tech products in Guangdong Province.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_1.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_1</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_1.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_2.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_2</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_2.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_3.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_3</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_3.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div class="fancy-box style-two" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000"
               data-aos-offset="50">
            <div class="image">
              <img src="/static/picture/Certificate_xs_4.jpg" alt="Fancy Box">
            </div>
            <div class="content">
              <p class="category">Certificate_4</p>
              <div class="inner-content">
                <a href="/static/picture/Certificate_4.jpg" class="mfp-iframe video-play"><i
                    class="fas fa-plus"></i></a>
              </div>
              <div class="bg">
                <img src="/static/picture/fancy-box-two-bg.png" alt="Shape">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Recent Certificate Area End -->

  <!-- Contact Page Start -->
  <section class="contact-page py-130 rpy-100">
    <div class="container">
      <div class="row gap-100 align-items-center">
        <div class="col-lg-5">
          <div class="contact-info-part">
            <div class="section-title mb-50" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
              <h2>Feel Free to Contact Us, Get In Touch</h2>
              <p>We're here to assist you in any way we can. Whether you have questions, feedback, or just want to say
                hello</p>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="fal fa-map-marker-alt"></i>
              </div>
              <div class="content">
                <span class="title">Location</span>
                <span
                    class="text">No. 1 Wanguo Market, Mashe Village, Lishui Town, Nanhai, Foshan, Guangdong, China</span>
              </div>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="far fa-envelope-open-text"></i>
              </div>
              <div class="content">
                <span class="title">Email Address</span>
                <span class="text">
                                        <a href="mailto:gdzg@gdzgsteel.com">gdzg@gdzgsteel.com</a>
                                    </span>
              </div>
            </div>
            <div class="contact-info-item style-two" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500"
                 data-aos-offset="50">
              <div class="icon">
                <i class="fal fa-phone-volume"></i>
              </div>
              <div class="content">
                <span class="title">Make A Call</span>
                <span class="text">
                                        <a href="calto:+86 18024194592">+86 180 2419 4592</a>
                                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="contact-form br-10 bgc-lighter rmt-60" name="contact-form" data-aos="fade-left"
               data-aos-duration="1500" data-aos-offset="50">
            <EmailForm/>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Page End -->

  <Footer/>
</template>

<script setup>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import {onMounted, ref} from "vue";
// import axios from "axios";
import EmailForm from "@/components/EmailForm.vue";
import {useHead} from "@vueuse/head";

useHead({
  title: 'TPA Housing - 100000m2 Production Plant, 20 Years Experienced Engineer, 30 Countries House Projects',
  meta: [
    {
      name: 'description',
      content: 'content="We can provide prefabricated design expertise, construction knowledge you need to build the house you want in short time and within your budget,Steel Structure House Building,Warehouse Steel Structure,Lightweight Steel Structure and other products and services'
    }
  ]
})

// const SlideData = ref(null)
//
// onMounted(() => {
//   get_index()
// })
//
// function get_index() {
//   axios
//       .get('/api/index/')
//       .then(response => {
//         SlideData.value = response.data.slide
//       })
//       .catch(err => {
//         console.log(err)
//       })
// }

</script>
