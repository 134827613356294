<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import axios from "axios";
import Related from "@/components/Related.vue";
import EmailForm from "@/components/EmailForm.vue";
import {useHead} from "@vueuse/head";

const route = useRoute()
const shopList = ref({})

function detailList(title) {
  axios
      .get('/api/products/?product_title=' + title)
      .then(response => {
        shopList.value = response.data.results[0]
      })
      .catch(error => {
        console.log(error)
      })
}

onMounted(() => {
  const title = route.params.str;
  detailList(title);
})

watch(shopList, (newVal) => {
  if (newVal) {
    useHead({
      title: newVal.product_title,
      meta: [
        {name: 'description', content: newVal.overview}
      ]
    })
  }
})
</script>

<template>
  <Header/>
  <!-- Page Banner Start -->
  <section class="page-banner-area overlay py-250 rpy-120 rel z-1 bgs-cover text-center"
           style="background-image: url(/static/image/Product.jpg);">
    <div class="container">
      <div class="banner-inner pt-70 rpt-60 text-white">
        <h1 v-if="shopList" class="page-title" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">{{ shopList.product_title }}</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
              data-aos-offset="50">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item"><a href="/products">Products</a></li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  <!-- Page Banner End -->


  <!-- Product Details Start -->
  <section class="product-details pb-10 pt-130 rpt-100">
    <div class="container">
      <div class="row gap-110">
        <div class="col-lg-7">
          <div class="product-details-images rmb-55" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
            <div class="tab-content preview-images">
              <div class="tab-pane fade preview-item active show" id="preview1">
                <img v-if="shopList" :src="shopList.image_1" alt="Perview">
              </div>
              <div class="tab-pane fade preview-item" id="preview2">
                <img v-if="shopList" :src="shopList.image_2" alt="Perview">
              </div>
              <div class="tab-pane fade preview-item" id="preview3">
                <img v-if="shopList" :src="shopList.image_3" alt="Perview">
              </div>
              <div class="tab-pane fade preview-item" id="preview4">
                <img v-if="shopList" :src="shopList.image_4" alt="Perview">
              </div>
            </div>
            <div class="nav thumb-images rmb-10">
              <a href="#preview1" data-bs-toggle="tab" class="thumb-item active show">
                <img v-if="shopList" :src="shopList.image_1" alt="Thumb">
              </a>
              <a href="#preview2" data-bs-toggle="tab" class="thumb-item">
                <img v-if="shopList" :src="shopList.image_2" alt="Thumb">
              </a>
              <a href="#preview3" data-bs-toggle="tab" class="thumb-item">
                <img v-if="shopList" :src="shopList.image_3" alt="Thumb">
              </a>
              <a href="#preview4" data-bs-toggle="tab" class="thumb-item">
                <img v-if="shopList" :src="shopList.image_4" alt="Thumb">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="product-details-content" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
            <div class="ratting mb-10">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="section-title">
              <h2 v-if="shopList">{{ shopList.product_title }}</h2>
            </div>
            <p v-if="shopList">{{ shopList.overview }}</p>
            <!-- Button trigger modal -->
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    data-bs-whatever="@getbootstrap">Quick Inquiry
            </button>

          </div>
        </div>
      </div>
      <ul class="nav nav advanced-tab style-two product-info-tab mt-90 mb-40" data-aos="fade-up"
          data-aos-duration="1500" data-aos-offset="50">
        <li><a href="#details" data-bs-toggle="tab" class="active show">Descrptions <i
            class="far fa-arrow-right"></i></a></li>
        <li><a href="#reviews" data-bs-toggle="tab"> parameter <i class="far fa-arrow-right"></i></a></li>
        <li><a href="#information" data-bs-toggle="tab">feature <i class="far fa-arrow-right"></i></a>
        </li>
      </ul>
      <div class="tab-content pb-30" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
           data-aos-offset="50">
        <div class="tab-pane fade active show" id="details">
          <p v-if="shopList" v-html="shopList.Description"></p>
        </div>
        <div class="tab-pane fade" id="reviews">
          <div class="comments">
            <div class="comment-body">
              <p v-if="shopList" v-html="shopList.parameter"></p>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="information">
          <p v-if="shopList" v-html="shopList.feature"></p>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">New message</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <EmailForm/>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Product Details End -->


  <!-- Related Product Area Start -->
  <Related/>
  <!-- Related Product Area End -->
  <Footer/>
</template>

<style scoped lang="scss">
.modal-backdrop {
  z-index: 1040 !important;
}

.modal {
  z-index: 1050 !important;
}
</style>