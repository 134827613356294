<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {useHead} from "@vueuse/head";

useHead({
  title: 'TPA Housing - 100000m2 Production Plant, 20 Years Experienced Engineer, 30 Countries House Projects',
  meta: [
    {
      name: 'description',
      content: 'content="We can provide prefabricated design expertise, construction knowledge you need to build the house you want in short time and within your budget,Steel Structure House Building,Warehouse Steel Structure,Lightweight Steel Structure and other products and services'
    }
  ]
})
</script>

<template>
  <Header/>
  <!-- 404 Error Area Start -->
  <section class="error-area py-150 rpy-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-7">
          <div class="error-content mt-80 rmt-60">
            <div class="image" data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="50">
              <i class="fal fa-exclamation-triangle display-1 text-primary"></i>
            </div>
            <h1 data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">Page not found</h1>
            <p data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500" data-aos-offset="50">It looks like you've
              ventured into uncharted territory. The page you were looking for may have been moved, deleted, or never
              existed in the first place. But don't worry, you're not lost for long.</p>
            <a data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500" data-aos-offset="50" class="btn btn-primary btn-lg" href="/" role="button">Link</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- 404 Error Area End -->
  <Footer/>
</template>

<style scoped lang="scss">

</style>