<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";

const dataForm = ref({
  email: '',
  client_path: '',
  client_ip: ''
})
const route = useRoute()
const statusMessage = ref('');

function get_ip() {
  axios
      .get('https://api.ipify.org?format=json')
      .then(response => {
        dataForm.value.client_ip = response.data.ip
      })
      .catch(error => {
        console.log(error)
      })
}

function send_email() {
  dataForm.value.client_path = route.path
  axios
      .post('/api/mail/', dataForm.value)
      .then(response => {
        statusMessage.value = 'Email sent successfully!';
      })
      .catch(error => {
        statusMessage.value = 'Failed to send email.';
      })
}

onMounted(() => {
  get_ip()
})
</script>

<template>
  <!-- footer area start -->
  <a id="floated" target="_blank" href="https://api.whatsapp.com/send?phone=8618024194592"><i
      class="fa fa-comment"></i> Welcome to consult us via WhatsApp </a>
  <footer class="main-footer footer-one rel z-1 mt-100">
    <div class="container">
      <div class="footer-newsletter text-white">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-5">
            <div class="logo-part" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
              <div class="logo w-25 h-25 mb-10">
                <a href=""><img src="/static/picture/logo1.jpg" alt="Logo"></a>
              </div>
              <p>Building Materials Technology Co.,Ltd.</p>
            </div>
          </div>
          <div class="col-xl-6 col-lg-7">
            <div class="form-part" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
              <h5>Please leave your email</h5>
              <form @submit='send_email' class="newsletter-form mt-15" action="#">
                <input v-model="dataForm.email" type="email" placeholder="Email Address" required="">
                <button type="submit">Sign Up <i class="far fa-arrow-right"></i></button>
              </form>
              <p v-if="statusMessage">{{ statusMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="footer-widget widget-about" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
            <h6 class="footer-title">About Company</h6>
            <p>We will do what the customer urgently needs, also can provide the unique value for our clients Choose us
              and choose the strongest backup force.</p>
            <div class="social-style-one">
              <a href="#"><i class="fab fa-twitter"></i></a>
              <a href="https://www.facebook.com/people/Tianpuan-Building-Materials-Technology-Co-Ltd/100093467225937/"><i
                  class="fab fa-facebook-f"></i></a>
              <a href="https://www.linkedin.com/in/%E5%AD%98%E6%80%A1-%E9%9F%A6-0668a7317/?locale=en_US"><i class="fab fa-linkedin"></i></a>
              <a href="https://www.instagram.com/tianpuanbuildingsteel/"><i class="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6 order-xl-2">
          <div class="footer-widget widget-contact" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500"
               data-aos-offset="50">
            <h6 class="footer-title">Contact</h6>
            <ul>
              <li><i class="fal fa-map-marker-alt"></i>No. 1 Wanguo Market, Mashe Village, Lishui Town, Nanhai, Foshan,
                Guangdong, China
              </li>
              <li><i class="fal fa-envelope"></i> <a href="mailto:gdzg@gdzgsteel.com">gdzg@gdzgsteel.com</a></li>
              <li><i class="fal fa-phone"></i> <a href="callto:+8618024194592">+86 18024194592</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="row">
            <div class="col-sm-4 col-6">
              <div class="footer-widget widget-links" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"
                   data-aos-offset="50">
                <h6 class="footer-title">Resources</h6>
                <ul>
                  <li><a href="/products">Product</a></li>
                  <li><a href="/contact">Contact</a></li>
                  <li><a href="/about">About Us</a></li>
                  <li><a href="/blogs">Blogs</a></li>
                </ul>
              </div>
            </div>
            <div class="col-sm-4 col-6">
              <div class="footer-widget widget-links" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500"
                   data-aos-offset="50">
                <h6 class="footer-title">Quick Link</h6>
                <ul>
                  <li><a href="/products">Features</a></li>
                  <li><a href="/projects">Best Program</a></li>
                </ul>
              </div>
            </div>
            <div class="col-sm-4 col-6">
              <div class="footer-widget widget-links" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500"
                   data-aos-offset="50">
                <h6 class="footer-title">Company</h6>
                <ul>
                  <li><a href="/about">About</a></li>
                  <li><a href="/products">Reviews</a></li>
                  <li><a href="/projects">Latest News</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom mt-30 py-15">
        <div class="row align-items-center">
          <div class="col-xl-4 col-lg-6">
            <div class="copyright-text pt-10 text-lg-start text-center" data-aos="fade-left" data-aos-duration="1000"
                 data-aos-offset="50">
              <p>Copyright &copy; 2024.Tpahousing All rights reserved.</p>
            </div>
          </div>
          <div class="col-xl-8 col-lg-6">
            <div class="footer-bottom-menu text-lg-end text-center" data-aos="fade-right" data-aos-duration="1000"
                 data-aos-offset="50">
              <ul>
                <li><a href="/contact">Faqs</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Scroll Top Button -->
        <button class="scroll-top scroll-to-target" data-target="html"><span class="far fa-angle-double-up"></span>
        </button>
      </div>
    </div>
  </footer>
  <!-- footer area end -->
</template>

<style scoped lang="scss">

</style>